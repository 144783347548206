// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()

import 'popper.js/dist/popper.min.js'
import 'bootstrap'
import 'css/site'

import 'selectize/dist/js/selectize.min.js'
import 'selectize/dist/css/selectize.css'
import { Dropdown } from 'bootstrap'

function setActiveListElements(event){
  // Get the offset of the window from the top of page  
  var windowPos = $(window).scrollTop();

  $('.mortgage-broker-home-navigation a[href^="#"]').each(function() { 
    var anchorId = $(this);
    var target = $(anchorId.attr("href"));
    
    if (target.length > 0) {
      if (target.position().top <= windowPos) {
        $('.nav-item').removeClass("active");
        anchorId.addClass('active');
      }
    }
  });
}

var prevScrollpos = window.pageYOffset;

$(window).on('scroll', function(){
  var scrollPos = $(window).scrollTop();

  if (scrollPos > 0) {
    $('.mortgage-broker-home-header, .main-header, .for-agents-header').addClass("scroll");
  }
  else {
    $('.mortgage-broker-home-header, .main-header, .for-agents-header').removeClass("scroll");
  }

  if($(window).outerWidth() > 768){
    var currentScrollPos = window.pageYOffset;

    if(!$('.mortgage-broker-home-header').hasClass('bg-white')){
      if (prevScrollpos > currentScrollPos) {
        $('.mortgage-broker-home-header').removeClass('hide');
      } else {
        $('.mortgage-broker-home-header').addClass('hide');
      }
    }

    prevScrollpos = currentScrollPos;
  }

  setActiveListElements();
});

window.addEventListener('load', () => {
  $('.form-control.select').selectize({
    persist: false,
    create: false,
    onChange: function(value){
      if(value > 0){
        $(".selectize-input").removeClass('error');
        validateFunction();
      }
    }
  });
  // $('[data-toggle="tooltip"]').tooltip()
});


function validateFunction(){
  $(".contact-us-form").validate({
    errorPlacement: function(error, elm) {
      if(elm.attr("name") == "contact[topic_id]") {
        $(".selectize-input").addClass('error');
        error.appendTo( elm.parent("div") );
      } else {
        error.insertAfter(elm);
      }
    },
    // Specify validation rules
    ignore: ':hidden:not([class~=selectized]),:hidden > .selectized, .selectize-control .selectize-input input',
    rules: {
      // The key name on the left side is the name attribute
      // of an input field. Validation rules are defined
      // on the right side
      'contact[phone_number]': {
        number: true,
        minlength: 10
      },
      'contact[email]': {
        // Specify that email should be validated
        // by the built-in "email" rule
        email: true
      },
      'contact[topic_id]': 'required',
      'contact[nmls_number]': 'required',
    },
    // Specify validation error messages
    messages: {
      'contact[topic_id]': "Topic can't be blank",
      'contact[name]': "Please enter your full name",
      'contact[phone_number]': {
        required: "Please enter a valid number",
        number: "Please enter a valid number",
        minlength: "Please enter minimum length required"
      },
      'contact[email]': "Please enter a valid email address",
      'contact[message]': "Please enter a message"
    },
    // Make sure the form is submitted to the destination defined
    // in the "action" attribute of the form when valid
    // submitHandler: function(form) { 
    //   form.submit();
    // }
  });


  $(".contact-us-mortgage-brokers-form").validate({
    errorPlacement: function(error, elm) {
      error.insertAfter(elm);
    },
    // Specify validation rules
    ignore: ':hidden:not([class~=selectized]),:hidden > .selectized, .selectize-control .selectize-input input',
    rules: {
      // The key name on the left side is the name attribute
      // of an input field. Validation rules are defined
      // on the right side
      'contact[phone_number]': {
        number: true,
        minlength: 10
      },
      'contact[email]': {
        // Specify that email should be validated
        // by the built-in "email" rule
        email: true
      },
      'contact[nmls_number]': 'required',
      'contact[message]': 'required'
    },
    // Specify validation error messages
    messages: {
      'contact[nmls_number]': "Please enter your NMLS number",
      'contact[name]': "Please enter your full name",
      'contact[phone_number]': {
        required: "Please enter a valid number",
        number: "Please enter a valid number",
        minlength: "Please enter minimum length required"
      },
      'contact[email]': "Please enter a valid email address",
      'contact[message]': "Please enter a message"
    },
    // Make sure the form is submitted to the destination defined
    // in the "action" attribute of the form when valid
    // submitHandler: function(form) { 
    //   form.submit();
    // }
  });
}

function initializeFilters(elmToBind, elmToFilter, onLoad){
  $(elmToBind).on('click', function(event){
    var tag = $(event.target).attr('id');
    
    $(elmToBind).removeClass('selected');
    $(this).addClass('selected');

    $(elmToFilter).show();
    $(elmToFilter).not('.' + tag).hide();
  });

  if(onLoad && $(elmToBind).hasClass('enableOnLoad')){
    var btn = $(elmToBind)[0];

    $(btn).trigger('click');
  }
}

function initializeFAQDropdown(question, answer){
  $.each($(answer), function(idx, item) {
    $(item).hide();
  });

  $(question).on('click', function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $(this).next(answer).hide();
      return;
    }

    $(answer).hide();
    $(question).removeClass('open');
    $(this).addClass('open');
    $(this).next(answer).show();
  })
}

$(document).ready(function(){
  validateFunction();

  $('.mortgage-broker-home-navigation a[href*="#"], .mortage-broker-sub-header a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
      event.preventDefault();

      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
        && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        var that = this;

        if (target.length) {
          $('.nav-item').removeClass("active");
          $(that).addClass('active');

          $('html, body').animate({
            scrollTop: target.offset().top - 20
          }, 500);
        }
      }
  });

  $('.mortgage-brokers-page .navbar-toggler').on('click', function(){
    $('.mortgage-broker-home-header').toggleClass('bg-white');

    $('body').toggleClass('stop-scrolling');
  });

  $('.contact-us-mortgage-brokers-form #contact_name, .contact-us-mortgage-brokers-form #contact_phone_number, .contact-us-mortgage-brokers-form #contact_email, .contact-us-mortgage-brokers-form #contact_nmls_number')
  .on('focus', function(){
    $(this).prev().css('transform', 'translateY(-15px)');
  })
  .on('blur', function(){
    if(!$(this).val()){
      $(this).prev().css('transform', 'translateY(10px)');
    }
  });

  initializeFilters('.resource.filters button', '.cards .outer-card', true);
  initializeFilters('.faq.filters button', '.faq-questions > div', true);
  initializeFAQDropdown('.faq-question','.faq-answer');
});

$(document).on('page:load', validateFunction);


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
//const images = require.context('../images', true)
//const imagePath = (name) => images(name, true)
